<template>
  <div id="app">
    <headermenu />
    <router-view />
    <subscribe />
    <footermenu />
  </div>
</template>
<script>
import $ from 'jquery'

export default {
  name: 'home',
  components: {
    'Headermenu': () => import('./components/Headermenu.vue'),
    'subscribe':() => import('./components/Subscribe.vue'),
    'Footermenu': () => import('./components/Footermenu.vue')
  },
  methods: {
    pageup () {
      $('html, body').animate({ scrollTop: 0 }, 300)
    }
  }
}
</script>
<style lang="scss">
@import 'styles/scss/elements.scss';
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
</style>
