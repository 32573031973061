import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

/* eslint-disable */
var home = ['Home']
home.forEach(function(element) {
  window[element] = () => import("@/components/homepage/" + element);
})

var about = ["About"];
about.forEach(function(element) {
  window[element] = () => import("@/components/about/" + element);
});

var member = ["Member"];
member.forEach(function(element) {
  window[element] = () => import("@/components/member/" + element);
});

var partner = ["Partner"];
partner.forEach(function(element) {
  window[element] = () => import("@/components/partner/" + element);
});

var helpcenter = ["HelpCenter"];
helpcenter.forEach(function(element) {
  window[element] = () => import("@/components/helpcenter/" + element);
});

var whatsnew = ["New"];
whatsnew.forEach(function(element) {
  window[element] = () => import("@/components/new/" + element);
});

var faq = ["Faq", "GettingStarted", "Membership", "Verification", "PartnerMerchant", "Complaints", "Disputes", "Deactivation", "AccountSecurity", "FeesAndCharges", "Maintenance", "Services", "BankTransfers"];
faq.forEach(function(element) {
  window[element] = () => import("@/components/faq/" + element);
});

var governance = ["TermsAndConditions", "PrivacyPolicy", "Amla", "Erm", "ConsumerProtection"];
governance.forEach(function(element) {
  window[element] = () => import("@/components/governance/" + element);
});


// var home = ['MobilePromoFaq', 'MobileFullDrawSchedule', 'MobilePromoMechanics']
// home.forEach(function(element) {
//   window[element] = () => import("@/components/" + element);
// })

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: '',
    component: About
  },
  {
    path: '/member',
    name: '',
    component: Member
  },
  {
    path: '/partner',
    name: 'partner',
    component: Partner
  },
  {
    path: '/helpcenter',
    name: 'helpcenter',
    component: HelpCenter
  },
  {
    path: '/instapay',
    name: 'new',
    component: New
  },
  // {
  //   path: '/mobilepromofaq',
  //   name: 'mobilepromofaq',
  //   component: MobilePromoFaq
  // },
  // {
  //   path: '/mobilefulldrawschedule',
  //   name: 'mobilefulldrawschedule',
  //   component: MobileFullDrawSchedule
  // },
  // {
  //   path: '/mobilepromomechanics',
  //   name: 'mobilepromomechanics',
  //   component: MobilePromoMechanics
  // },
  {
    path: '/faq',
    name: 'faq',
    component: Faq,
    children: [
      {
        path: 'gettingstarted',
        name: 'gettingstarted',
        component: GettingStarted
      },
      {
        path: 'membership',
        name: 'membership',
        component: Membership
      },
      {
        path: 'verification',
        name: 'verification',
        component: Verification
      },
      {
        path: 'partnermerchant',
        name: 'partnermerchant',
        component: PartnerMerchant
      },
      {
        path: 'complaints',
        name: 'complaints',
        component: Complaints
      },
      {
        path: 'disputes',
        name: 'disputes',
        component: Disputes
      },
      {
        path: 'deactivation',
        name: 'deactivation',
        component: Deactivation
      },
      {
        path: 'accountsecurity',
        name: 'accountsecurity',
        component: AccountSecurity
      },
      {
        path: 'feesandcharges',
        name: 'feesandcharges',
        component: FeesAndCharges
      },
      {
        path: 'maintenance',
        name: 'maintenance',
        component: Maintenance
      },
      {
        path: 'services',
        name: 'services',
        component: Services
      }
    ]
  },
  {
    path: '/termsandconditions',
    name: 'termsandconditions',
    component: TermsAndConditions
  },
  {
    path: '/privacypolicy',
    name: 'privacypolicy',
    component: PrivacyPolicy
  },
  {
    path: '/amla',
    name: 'amla',
    component: Amla
  },
  {
    path: '/erm',
    name: 'erm',
    component: Erm
  },
  {
    path: '/consumerprotection',
    name: 'consumerprotection',
    component: ConsumerProtection
  },
  // {
  //   path: '/promo',
  //   name: 'promo',
  //   component: Promo
  // },
  { path: '/404', component: Home },
  { path: '/:catchAll(.*)', redirect: '/' },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  history: true,
  routes,
  scrollBehavior: function(to, from, savedPosition) {
    const position = {}

    if (to.hash) {
      position.selector = to.hash
      position.offset = { x: 0, y: 0 }
      position.behavior = 'smooth'
      position.block = 'start'

      if // gettingstarted
      (to.hash === '#gts1' ||
      to.hash === '#gts2' ||
      //membership
      to.hash === '#mbs1' ||
      to.hash === '#mbs2' ||
      to.hash === '#mbs3' ||
      //verificatiom
      to.hash === '#v1' ||
      to.hash === '#v2' ||
      to.hash === '#v3' ||
      //partner merchant
      to.hash === '#pm1' ||
      to.hash === '#pm2' ||
      to.hash === '#pm3' ||
      to.hash === '#pm4' ||
      //tayocash services cash in
      to.hash === '#c_i1' ||
      to.hash === '#c_i2' ||
      to.hash === '#c_i3' ||
      to.hash === '#c_i4' ||
      to.hash === '#c_i5' ||
      to.hash === '#c_i6' ||
      to.hash === '#c_i7' ||
      //tayocash services cash out
      // to.hash === '#c_o1' ||
      // to.hash === '#c_o2' ||
      to.hash === '#c_o3' ||
      to.hash === '#c_o4' ||
      to.hash === '#c_o5' ||
      to.hash === '#c_o6' ||
      to.hash === '#c_o7' ||
      to.hash === '#c_o8' ||
      to.hash === '#c_o9' ||
      to.hash === '#c_o10' ||
      //tayocash services buy load
      to.hash === '#bl1' ||
      to.hash === '#bl2' ||
      to.hash === '#bl3' ||
      to.hash === '#bl4' ||
      //tayocash services bank transfers
      to.hash === '#bt1' ||
      to.hash === '#bt2' ||
      to.hash === '#bt3' ||
      to.hash === '#bt4' ||
      to.hash === '#bt5' ||
      //tayocash services fund transfer
      to.hash === '#ft1' ||
      to.hash === '#ft2' ||
      to.hash === '#ft3' ||
      to.hash === '#ft4' ||
      to.hash === '#ft5' ||
      to.hash === '#ft6' ||
      //tayocash services pay bills
      to.hash === '#pb1' ||
      to.hash === '#pb2' ||
      to.hash === '#pb3' ||
      //tayocash services pay QR
      to.hash === '#payqr' ||
      to.hash === '#pqr1' ||
      to.hash === '#pqr2' ||
      to.hash === '#pqr3' ||
      to.hash === '#pqr4' ||
      to.hash === '#pqr5' ||
      //complaints
      to.hash === '#cmplt1' ||
      to.hash === '#cmplt2' ||
      //disputes
      to.hash === '#dspt1' ||
      to.hash === '#dspt2' ||
      to.hash === '#dspt3' ||
      to.hash === '#dspt4' ||
      to.hash === '#dspt5' ||
      //account maintenance/ management
      to.hash === '#accmm1' ||
      to.hash === '#accmm2' ||
      to.hash === '#accmm3' ||
      to.hash === '#accmm4' ||
      to.hash === '#accmm5' ||
      to.hash === '#accmm6' ||
      to.hash === '#accmm7' ||
      to.hash === '#accmm8' ||
      to.hash === '#accmm9' ||
      to.hash === '#accmm10' ||
      to.hash === '#accmm11' ||
      to.hash === '#accmm12' ||
      to.hash === '#accmm13' ||
      to.hash === '#accmm14' ||
      //account deactivation and termination
      to.hash === '#accdt1' ||
      to.hash === '#accdt2' ||
      to.hash === '#accdt3' ||
      //account security
      to.hash === '#accsec1' ||
      to.hash === '#accsec2' ||
      to.hash === '#accsec3' ||
      to.hash === '#accsec4' ||

      //instaPay
      to.hash === '#new' ||

      //tayocash fees and charges
      to.hash === '#tcfc1') {
        position.offset = { x: 0, y: 85 }
        position.behavior = 'smooth'
        position.block = 'start'
      }

      if (/^#\d/.test(to.hash) || document.querySelector(to.hash)) {
        return position
      }
    }
    if (savedPosition) {
        // savedPosition is only available for popstate navigations.
      return savedPosition
    }
  }
})

export default router
