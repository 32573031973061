import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import CryptoJS from "crypto-js";
import jQuery from 'jquery'
window.jQuery = window.$ = jQuery

import 'fomantic-ui-css/semantic.min.css'
require('fomantic-ui-css/semantic.js')

import 'vueperslides/dist/vueperslides.css'

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)

import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAEwpUDLVwlc51OqekHnqvUQyiRSCXtoN0",
  authDomain: "tayocash-1ee7e.firebaseapp.com",
  databaseURL: "https://tayocash-1ee7e.firebaseio.com",
  projectId: "tayocash-1ee7e",
  storageBucket: "tayocash-1ee7e.appspot.com",
  messagingSenderId: "456686545468",
  appId: "1:456686545468:web:c516eaa5b484276e037139",
  measurementId: "G-ECLHR2XWLF"
};

firebase.default.initializeApp(firebaseConfig);
firebase.default.analytics();

Vue.config.productionTip = false

// alias
Vue.prototype.$analytics = firebase.default.analytics();

Vue.mixin({
  methods: {
    encrypted: function(strToEncryt) {
      var cryptobject = "";
      var encrypted = "";
      cryptobject = CryptoJS.AES.encrypt(
        strToEncryt,
        CryptoJS.enc.Utf8.parse(""),
        { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
      );

      encrypted = {
        key: cryptobject.key + "",
        iv: cryptobject.iv + "",
        salt: cryptobject.salt + "",
        ciphertext: cryptobject.ciphertext + "",
        str: cryptobject + ""
      };
      return encrypted.str;
    },
    decrypted: function(strToDecryt) {
      if (strToDecryt == null) {
        strToDecryt = "";
      }

      var todecrypt;
      todecrypt = CryptoJS.AES.decrypt(
        strToDecryt,
        CryptoJS.enc.Utf8.parse(""),
        { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
      ).toString(CryptoJS.enc.Latin1);
      return todecrypt;
    },
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
