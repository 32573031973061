import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const url = process.env.VUE_APP_BASEURL;
const headers = { Accept: "application/json" };

export default new Vuex.Store({
  state: {
    helpTopic: [],
    // promoSchedule: []
  },
  mutations: {
    //syncrous
    setHelpTopic(state, help) {
      
      state.helpTopic = help

    },
    // getPromoSchedule(state, promo) {
    //   state.promoSchedule = promo
    // }
  },
  actions: {
    //asyncronous
    async setHelpTopic(state) {
      const joke = await fetch(url + "/help-topic", { headers });
      const j = await joke.json();
      state.commit("setHelpTopic", j.data);
    },
    createHelpCenter(context, form) {
      
      let formData = new FormData();
      //formData.append("description", "test");
      formData.append("help_topic_id", form.help_topic_id);
      formData.append("contact_number", form.contactnumberEncrypted);
      formData.append("name", form.name);
      formData.append("email", form.emailEncrypted);
      formData.append("message", form.message);
      
      for (var a = 0; a < form.document.length; a++) {
        let file = form.document[a];
  
        formData.append("document[" + a + "]", file);
      }
      
      formData.append("source", "web");

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: url + "/help",
          data: formData,
          headers: {'Content-Type': 'multipart/form-data',  'Accept': 'application/json'}
        })
        .then(function (response) {
          resolve(response);
          
        })
        .catch(function (error) {
          
          reject(error);

        });

      });
    },
    // async getPromoSchedule(state) {
    //   const promo = await fetch("https://act.upload.uat.tayocash.com/promo/schedules", { headers });
    //   const p = await promo.json();
    //   state.commit("getPromoSchedule", p.data);
    // }
    // getPromoSchedule(){

    //   return new Promise((resolve, reject) => {
    //     axios({
    //       method: 'get',
    //       url: "https://act.upload.uat.tayocash.com/promo/schedules",
    //       // data: formData,
    //       // headers: {'Content-Type': 'multipart/form-data',  'Accept': 'application/json'}
    //     })
    //     .then(function (response) {
    //       console.log(response)
    //       resolve(response);
          
    //     })
    //     .catch(function (error) {
    //       console.log(error)
    //       reject(error);

    //     });
    //     // axios
    //     //   .get("https://act.upload.uat.tayocash.com/promo/schedules", 
    //     //       { cancelToken: axiosSource.token })
    //     //   .then(response => {
    //     //     console.log(response)
    //     //     actions.stopTimer()
    //     //     context.commit("getPromoSchedule", response.data);
    //     //     resolve(response);
    //     //   })
    //     //   .catch(error => {
    //     //     actions.stopTimer()
    //     //     console.log(error)
    //     //     reject(error);
    //     // });

    //   });

    // }
    
  },
  modules: {},
  getters: {
    getHelpTopic: state => state.helpTopic,
    // adminGetPromoSchedule: state => state.promoSchedule,
  }
});
